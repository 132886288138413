<template>
    <div class="block-badge-tag" :class="{ 'full-badge': fullBadge }">
        <img :src="$assets.primary.verified" alt="verificado" width="12" height="12" laziload="lazy" />
        <span
            class="text"
            :class="[textStyle, textStyle === 'hidden' && (showTextInMobile ? 'block' : 'hidden sm:block')]"
        >
            {{ text }}
        </span>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MicroBadge',
    props: {
        text: {
            type: String,
        },
        textStyle: {
            type: String,
            default: 'text-xs',
        },
        showTextInMobile: {
            type: Boolean,
            default: false,
        },
        fullBadge: {
            type: Boolean,
            default: true,
        },
    },
})
</script>

<style lang="postcss" scoped>
.block-badge-tag {
    @apply flex w-fit items-center space-x-1 px-1 py-0.5;
    .text {
        @apply font-medium capitalize text-site-primary;
    }
    img {
        @apply h-3 w-3;
    }
}

.full-badge {
    @apply rounded-xl bg-white px-2;
    box-shadow:
        0 0 25px rgb(0 0 0 / 0.1),
        0 8px 10px -6px rgb(0 0 0 / 0.1);
}
</style>
